// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../dashboard.css'
import '../DashboardPage/Main.css'
import TopBar from '../DashboardPage/TopBar';
import Sidebar from '../DashboardPage/Sdebar';
import MainContent from '../DashboardPage/MainContent';
import ClientAdmin from './ClientAdmin';

const AdminDashboard = () => {
  const navigate = useNavigate();  // Hook to programmatically navigate
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [message, setMessage] = useState('');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      // If no token, redirect to login page
      navigate('/admin-login');
    }
  }, [navigate]);

  return (
    <div>
       {/* <header  className='admin-dashboard'>
            <p className='subtitle color-white text-center'>Welcome to Admin Pannel</p>
       </header> */}
             <div className="dashboard-layout">
             <TopBar toggleSidebar={toggleSidebar} message={message} />
        <div className="dashboard-body">
        <Sidebar isOpen={isSidebarOpen} />

          <div className="dashboard-content">
          {/* <BrowserRouter>
          <Routes>
               <Route path="/dashboard" element={MainContent} />
               <Route path="/" exact element={MainContent} />
              You can add other routes for Profile, Settings, etc.
           </Routes>
          </BrowserRouter> */}
               <ClientAdmin />
              <MainContent />
          </div>
        </div>
      </div>

      {/* <button onClick={() => {
        ///Logout: Remove the token and redirect to login page
        localStorage.removeItem('authToken');
        navigate('/admin-login');
      }}>
        Logout
      </button> */}
    </div>
  );
};

export default AdminDashboard;
