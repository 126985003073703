import React from "react";
import parent from '../../assets/images/gallery/1_green.png'
import submitted from '../../assets/images/gallery/2_green.png'
import idgenerated from '../../assets/images/gallery/3_green.png'
import invovation from '../../assets/images/gallery/4_green.png'

const itemData = [
    {
        id: 1,
        icon: parent,
        title: 'Educating Parents',
        subtitle: 'Parents visit school to learn about APAAR ID ID creation'
    },

    {
        id: 2,
        icon: submitted,
        title: 'Consent Submitted',
        subtitle: 'Parents fill up consent form voluntarily for APAAR ID creation'
    },
    {
        id: 3,
        icon: idgenerated,
        title: 'APAAR ID Generated',
        subtitle: 'UDISE system generates APAAR ID against PEN ID of studentlo'
    },
    {
        id: 4,
        icon: invovation,
        title: 'Uploaded to DigiLockers',
        subtitle: 'APAAR ID is pushed into students DigiLocker Account '
    },


];
const ProcessApaar = () => {
    return (
        <div>
            <h1 className="title text-center">How to Get The APAAR</h1>
            <p className='subtitle color-black text-center'>Are you a student? Be a part of the APAAR by creating your Unique ID</p>
            <div className='list-img-section clearfixed'>

                {
                    itemData.map((list) => {
                        return (
                            <div key={list.id} className='float-left-img'>
                               
                                    <div className='process-img'>
                                        <div className='img-title' >
                                            <img src={list.icon} className='fade-img' />
                                            <h2 className='title'>{list.title}</h2>
                                            <h2 className='apaar-subtitle'>{list.subtitle}</h2>
                                        </div>
                                    </div>
                            
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default ProcessApaar







