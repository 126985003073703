import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './home.css'
import SingleSlider from '../../CommonComponents/Sliders/SingleSlider'
import ImagesList from '../Services/ImagesList'
import ProcessApaar from '../Services/ProcessApaar'
import AboutTheApaar from '../About/AboutTheApaar'
import MutipleSlidesPerView from '../../CommonComponents/Sliders/MutipleSlidesPerView'

const Home = () => {
    return (
        <div>
            <Header />
            <div className='home-m-t'>
            </div>
            <section className='w-100 text-relative'>
                <div className='text-absolute'>
                    AUTOMATED PERMANENT ACADEMIC ACCOUNT REGISTRY
                </div>
                <SingleSlider />
            </section>
            <section>
                <section className='bgcolor-blue'>
                    <section className='common-width'>
                        <h1 className='title text-center color-white common-m-tb'>
                            Our Services
                        </h1>
                        <p className='subtitle color-white text-center'> <strong>Process To Get The APAAR CARD</strong>
                            <br />

                        </p>
                        <p className='subtitle color-white text-center text-justify'>APAAR, which stands for Automated Permanent Academic Account Registry, is a specialized identification system designed for all students in India. This initiative is part of the 'One Nation, One Student ID' program launched by the government, aligning with the new National Education Policy of 2020.</p>
                        <p className='subtitle color-white text-center text-justify'>Your gateway to a seamless, digital academic journey. APAAR ID helps in a seamless integration of skills and experience into a credit-based system.</p>
                        <p className='subtitle color-white text-center text-center'> <strong>One Nation, One Student ID</strong></p>
                    </section>
                </section>
                <section className='common-width'>
                    <ImagesList />
                </section>
            </section>


            <section className='bgcolor-blue'>
                <AboutTheApaar />
            </section>
            <section className='common-width'>
                <ProcessApaar />
            </section>
            <section className='bgcolor-blue pb-20'>
                <section className='common-width'>
                    <h1 className='title text-center color-white'>
                        Our Client
                    </h1>
                    <MutipleSlidesPerView />
                </section>
            </section>


            <Footer />
        </div>
    )
}

export default Home