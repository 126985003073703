import React from 'react'

import './gallery.css'
import gallery1 from '../../assets/images/gallery/gallery1.jfif'
import gallery2 from '../../assets/images/gallery/gallery2.jpeg'
import gallery3 from '../../assets/images/gallery/gallery3.jpeg'
import gallery4 from '../../assets/images/gallery/gallery4.jpg'
import gallery5 from '../../assets/images/gallery/gallery5.jpg'

const imglist = [
     {
        list:gallery1
     },
     {
        list:gallery2
     },
     {
        list:gallery3
     },
     {
        list:gallery4
     },
     {
        list:gallery5
     },
     {
        list:gallery2
     },
]


const GalleySection = () => {
    return (
        <div>
            <section className='clearfixed'>
                {
                    imglist.map((item) => {
                        return (
                            <div className='galley-left'>
                                   <div className='gallery-img'>
                                       <img src={item.list}  />
                                   </div>
                            </div>
                        )
                    })
                }
            </section>
        </div>
    )
}

export default GalleySection