import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import '../login.css'
const Login = () => {
    const navigate = useNavigate();  // Hook to programmatically navigate
    // States for form data and validation errors
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const [isLoading, setIsLoading] = useState(false);  // Loading state for API call
    const [apiResponse, setApiResponse] = useState(null);  // Store API response

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Simple form validation
    const validateForm = () => {
        let valid = true;
        const newErrors = { email: '', password: '' };

        if (!formData.email) {
            newErrors.email = 'Email is required.';
            valid = false;
        }

        if (!formData.password) {
            newErrors.password = 'Password is required.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the form inputs
        if (!validateForm()) return;

        // Set loading state to true
        setIsLoading(true);
        setApiResponse(null);

        // Make the API call using Axios
        axios
            .post('https://api.escuelajs.co/api/v1/auth/login',  formData) // Replace with your actual API URL
            .then((response) => {
                // Save the JWT token or user info (Example: store token in localStorage)
                localStorage.setItem("token", response.data.token);
                setIsLoading(false);
                setApiResponse({ type: 'success', message: 'Login successful!' });
                // On success, redirect to the dashboard page
                 // "email": "john@mail.com",
                 // "password": "changeme",
               
                navigate("/dashboard");  // Redirect to the Dashboard page
                // Optionally, store token or user data in localStorage/sessionStorage or context
                // localStorage.setItem('token', response.data.token);
            })
            .catch((error) => {
                setIsLoading(false);
                setApiResponse({ type: 'error', message: error.response?.data?.message || 'An error occurred!' });
            });
    };

    return (
        <div className="">
            <Header />
            <div className='common-m-t'>
            </div>
            <section className='common-width'>
                <section className='common-width-login'>
                    <h1 className="title text-center">Please Login Here</h1>
                    {apiResponse && (
                        <div className={`alert ${apiResponse.type === 'success' ? 'success' : 'error'}`}>
                            {apiResponse.message}
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="login-form">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <small className="error">{errors.email}</small>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            {errors.password && <small className="error">{errors.password}</small>}
                        </div>

                        <button type="submit" className="submit-button" disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                </section>
            </section>
            <div className='p-t-b-100'>
            </div>
            <Footer />
        </div>

    );
};

export default Login;
