import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';  // uuid to generate unique client IDs

const ClientAdmin = () => {
  const [clients, setClients] = useState(() => {
    const savedClients = localStorage.getItem('clients');
    return savedClients ? JSON.parse(savedClients) : [];
  });
  const [newClient, setNewClient] = useState('');
  useEffect(() => {
    localStorage.setItem('clients', JSON.stringify(clients));
  }, [clients]);

  const handleInputChange = (e) => {
    setNewClient(e.target.value);
  };
  const handleAddClient = () => {
    if (newClient.trim()) {
      const newClientObj = {
        id: uuidv4(),  // Generate unique ID for each client
        name: newClient.trim(),
      };
      setClients([...clients, newClientObj]);
      setNewClient(""); // Clear the input field after adding
    } else {
      alert("Please enter a client name.");
    }
  };

  const handleRemoveClient = (id) => {
    setClients(clients.filter(client => client.id !== id));
  };

  return (
    <div>
      <h1>Admin - Client Management</h1>

      <div>
        <input
          type="text"
          value={newClient}
          onChange={(e) => setNewClient(e.target.value)}
          placeholder="Enter client name"
        />
        <button onClick={handleAddClient}>Add Client</button>
      </div>

      <ul>
        {clients.map((client) => (
          <li key={client.id}>
            {client.name}{" "}
            <button onClick={() => handleRemoveClient(client.id)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClientAdmin;
