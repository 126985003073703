import React, { useState, useEffect } from 'react';

import './Client.css'
const ClientDemoDashboard = () => {
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    // Simulating an API call
    setTimeout(() => {
      setClientData({
        name: "Client Name",
        address: "123 Client St, City, Country",
        recentActivity: [
          "New Message Received",
          "Profile Updated",
          "Task Completed"
        ]
      });
    }, 1000);
  }, []);

  if (!clientData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="client-dashboard">
      <h2>Client Dashboard</h2>
      <div className="client-info">
        <h3>{clientData.name}</h3>
        <p>{clientData.address}</p>
        <button>Edit Profile</button>
      </div>
      <div className="client-activity">
        <h3>Recent Activity</h3>
        <ul>
          {clientData.recentActivity.map((activity, index) => (
            <li key={index}>{activity}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ClientDemoDashboard;
