import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slider.css'
import Parents from '../../assets/images/gallery/apaar5.png'
import Submitted from '../../assets/images/gallery/apaar3.png'
import Generated from '../../assets/images/gallery/apaar5.png'
import DigiLocker from '../../assets/images/gallery/apaar2.jpg'
import Nation from '../../assets/images/gallery/apaar6.png'
import Students from '../../assets/images/gallery/apaar1.jpg'

const MutipleSlidesPerView = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    className: 'img-center',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="img-center">
        <img src={Parents} />
      </div>
      <div className="img-center">
        <img src={Submitted} />
      </div>
      <div className="img-center">
        <img src={Generated} />
      </div>
      <div className="img-center">
        <img src={DigiLocker} />
      </div>
      <div className="img-center">
        <img src={Nation} />
      </div>
      <div className="img-center">
        <img src={Students} />
      </div>
    </Slider>
  );
}

export default MutipleSlidesPerView;