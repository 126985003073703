import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../Components/Home/Home';
import About from '../Components/About/About';
import Services from '../Components/Services/Services';
import Faq from '../Components/Faq/Faq';
import TermsAndCondition from '../Components/TermsAndCondition/TermsAndCondition';
import Privacy from '../Components/Privacy/Privacy';
import Gallery from '../Components/Gallery/Gallery';
import Register from '../Components/LoginPage/Register/Register';
import Contact from '../Components/LoginPage/Contact/Contact';
import Login from '../Components/LoginPage/Login/Login';
import Teams from '../Components/Teams/Teams';
import Dashboard from '../Components/LoginPage/Dashboard/Dshboard';
import AdminDashboard from '../Components/LoginPage/Dashboard/Admin/AdminDashboard';
import AdminLogin from '../Components/LoginPage/Login/AdminLogin';
import ClientDashboard from '../Components/LoginPage/Dashboard/Client/ClientDashboard';
import ClientLogin from '../Components/LoginPage/Login/ClientLogin';

const Router = ()=>{
    return(
        <div>
            <BrowserRouter>
               <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/about' element={<About />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route path='/services' element={<Services />} />
                  <Route path='/gallery' element={<Gallery />} />
                  <Route path='/teams' element={<Teams />} />
                  {/* Login router */}
                  <Route path='/admin-login' element={<AdminLogin />} />
                  <Route path='/admin-dashboard' element={<AdminDashboard />} />
                  <Route path='/client-login' element={<ClientLogin />} />
                  <Route path='/client-dashboard' element={<ClientDashboard />} />
                  <Route path='login' element={<Login />} />
                  <Route path='register' element={<Register />} />
                  <Route path='dashboard' element={<Dashboard />} />
                  
                  {/* footer Page */}
                  <Route path='privacy' element={<Privacy />} />
                  <Route path='terms-and-condition' element={<TermsAndCondition />} />
                  <Route path='faq' element={<Faq />} />
               </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Router