import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const ClientLogin = () => {
  const navigate = useNavigate();  // Hook to programmatically navigate
  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // Loading state for API call
  const [apiResponse, setApiResponse] = useState(null);  // Store API response

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = { email: '', password: '' };

    if (!formData.email) {
      newErrors.email = 'Email is required.';
      valid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Password is required.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    // Simple "fake" login, just for simulation

    if (formData.email === 'arusheducati@gmail.com' && formData.password === 'Balgobind@5') {
      // Store a token (here, just a simple string for demo purposes)
      const token = 'fake-jwt-token';
      localStorage.setItem('authToken', token);

      // Redirect to the Dashboard
      navigate('/client-dashboard');
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className="">
      <Header />
      <div className='common-m-t'>
      </div>
      <section className='common-width'>
        <section className='common-width-login'>
        <h1 className="title text-center">Please Client Login Here</h1>
          {apiResponse && (
            <div className={`alert ${apiResponse.type === 'success' ? 'success' : 'error'}`}>
              {apiResponse.message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <small className="error">{errors.email}</small>}
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <small className="error">{errors.password}</small>}
            </div>

            <button type="submit" className="submit-button" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </section>
      </section>
      <div className='p-t-b-100'>
      </div>
      <Footer />
    </div>
  );
};

export default ClientLogin;
