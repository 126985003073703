import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'


const Privacy = ()=>{
    return(
        <div>
             <Header />
             <div className='common-m-t'>
             </div>
              <h1>Privacy page</h1>
            <Footer />
        </div>
    )
}

export default Privacy