import React, { useState } from "react";

import '../login.css'
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
const Contact = () => {
    // Define state for form fields and validation messages
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        msg: "",
    

    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        mobile: "",
        msg: "",
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Simple validation function
    const validateForm = () => {
        let valid = true;
        const newErrors = {
            name: "",
            email: "",
            mobile: "",
            msg: "",
        };

        if (!formData.name) {
            newErrors.name = "Name is required";
            valid = false;
        }


        if (!formData.email) {
            newErrors.email = "Email is required";
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is invalid";
            valid = false;
        }
        if (!formData.mobile) {
            newErrors.mobile = "Mobile Number is required";
            valid = false;
        }

        if (!formData.msg) {
            newErrors.msg = "Message is required";
            valid = false;
        }


        setErrors(newErrors);
        return valid;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            alert("Form submitted successfully!");
            // You can proceed to submit the form data to an API or backend
        }
        // const mailtoLink = `mailto:${formData.recipient}?subject=Contact Form Submission&body=Name: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0AMessage: ${formData.message}`;
        // window.location.href = mailtoLink;
        setFormData({
            name: "",
            email: "",
            mobile: "",
            msg: "",
          });
    };

    return (
        <div className="">
            <Header />
            <div className='common-m-t'>
            </div>
            <section className='common-width'>
                <section className='common-width-login'>
                    <h1 className="title text-center">Please Contact Here</h1>
                    <form onSubmit={handleSubmit} className="login-form">
                        <div className="form-group">
                            <label htmlFor="name">Full Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {errors.name && <small className="error">{errors.name}</small>}
                        </div>


                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <small className="error">{errors.email}</small>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                            <input
                                type="text"
                                id="mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                            />
                            {errors.mobile && <small className="error">{errors.mobile}</small>}
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="msg">Message</label>
                            <textarea
                                type="text"
                                id="msg"
                                name="msg"
                                value={formData.msg}
                                onChange={handleChange}
                            />
                            {errors.msg && <small className="error">{errors.msg}</small>}
                        </div>

                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                </section>
            </section>
            <div className='p-t-b-100'>
            </div>
            <Footer />

        </div>
    );
};

export default Contact;
