import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slider.css'
import Slide1 from '../../assets/images/gallery/Kashi-10.jpg'
import Slide2 from '../../assets/images/gallery/Kashi-8.jpg'
import Slide3 from '../../assets/images/gallery/Kashi-2.jpg'
const SingleSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    className: 'img-center',

    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="img-center">
        <img src={Slide1} />
      </div>
      <div className="img-center">
        <img src={Slide2} />
      </div>
      <div className="img-center">
        <img src={Slide3} />
      </div>

    </Slider>
  );
}

export default SingleSlider;