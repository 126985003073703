import React from 'react';
import './Client.css'
const ClientSidebar = () => {
  return (
    <div className="client-sidebar">
      <ul>
        <li><a href="/">Overview</a></li>
        <li><a href="/recent-activity">Recent Activity</a></li>
        <li><a href="/notifications">Notifications</a></li>
        <li><a href="/settings">Settings</a></li>
      </ul>
    </div>
  );
};

export default ClientSidebar;
