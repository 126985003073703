import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';  // If using React Router for navigation
import './Client.css'
const ClientNavbar = () => {
    const navigate = useNavigate();  // Hook to programmatically navigate
  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      // If no token, redirect to login page
      navigate('/client-login');
    }
  }, [navigate]);

  return (
    <nav className="client-navbar">
      <div className="navbar-logo">
        <img src="/assets/logo.png" alt="Client Logo" />
        <span className="client-name">Client Name</span>
      </div>
      <div className="navbar-links">
        <Link to="/">Home</Link>
        <Link to="/profile">Profile</Link>
        <Link to="/settings">Settings</Link>
        <button onClick={() => {
        localStorage.removeItem('authToken');
        navigate('/client-login');
      }}  className="logout-btn" >
        Logout
      </button>
      </div>
    </nav>
  );
};

export default ClientNavbar;
