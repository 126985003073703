import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AboutTheApaar from './AboutTheApaar'
import abouts from '../../assets/images/gallery/about.jpg'
import MutipleSlidesPerView from '../../CommonComponents/Sliders/MutipleSlidesPerView'

const About = ()=>{
    return(
        <div>
             <Header />
             <div className='common-m-t'>
             </div>
             <section className='common-width'>
                <h1 className="title text-center">About The Arush Foundation</h1>
                  <p className='subtitle text-center'> <strong>Welcome to ARUSH HEALTH FOUNDATION</strong></p>
                  <p>Arush Eduction and Helth foundtion is a Non-Government, Non-Political and Non-Profit voluntary organization registered under society act with registration number u88900UP2023NP L188522 and is approved by the Central and UP Government. It was established in 2023 with a commitment to change the social life style of the rural areas of Bhrat. We work for various different social cause including Water Sanitation, Woman Empowerment, Environment Protection, Edction,skill development,Helth ,AIDS Awareness etc.</p>
             </section>
             <section className='bgcolor-blue'>
                <section className='common-width'>
                <div className='clearfixed'>
                      <div className='about-float'>
                         <div className='about-img'>
                              <img src={abouts} />
                         </div>
                      </div>
                      <div className='about-float'>
                          <p className='about-subtitle'>
                          Arush Eduction and Helth foundtion is a Non-Government, Non-Political and Non-Profit voluntary organization registered under society act with registration number u88900UP2023NP L188522 and is approved by the Central and UP Government. It was established in 2023 with a commitment to change the social life style of the rural areas of Bhrat. We work for various different social cause including Water Sanitation, Woman Empowerment, Environment Protection, Edction,skill development,Helth ,AIDS Awareness etc.
                          </p>
                          <p className='about-subtitle'>
                          APAAR, which stands for Automated Permanent Academic Account Registry, is a specialized identification system designed for all students in India. This initiative is part of the 'One Nation, One Student ID' program launched by the government, aligning with the new National Education Policy of 2020.
                          </p>
                      </div>
                  </div>
                </section>
             </section>
             <section className='about-client'>
                 <section className='common-width'>
                      <h1 className='title text-center'>Our Client</h1>
                      <MutipleSlidesPerView />
                  </section>
             </section>
             <section className='bgcolor-blue'>
                  <AboutTheApaar />
               </section>
            <Footer />
        </div>
    )
}

export default About