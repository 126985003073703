import * as React from 'react';
import { Link } from 'react-router-dom';
import './footer.css'
const  Footer = () =>{

  return (
        <div className='footer-text'>
         <ul className='footer-bottom'>
            <li className=''>
               <Link to="/">Privacy Policy</Link>
            </li>
            <li  className=''>
               <Link to="/">Terms & conditions</Link>
            </li>
            <li  className=''>
               <Link to="/">FAQ</Link>
            </li>
            <li className='' style={{color:'white'}}>
              © Arush Foundation 2024. <br />
              Office Location: Akabarpur Mau U.P, Mumbai Maharastra

            </li>
            <li  className=''>
               <a href="#" target='_blank' className="fa fa-facebook"></a>
               <a href="#" target='_blank' className="fa fa-twitter"></a>
               <a href="#" target='_blank' className="fa fa-linkedin"></a>
               <a href="#" target='_blank' className="fa fa-youtube"></a>
               <a href="#" target='_blank' className="fa fa-instagram"></a>
            </li>
            <li className='text-align-right color-white'>Website designed by <br />Mr Ram Bhardwaj :(2024)</li>
         </ul>
         </div>
  );
}
export default Footer;
