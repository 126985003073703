import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import GalleySection from './GalleySection'



const Gallery = ()=>{
    return(
        <div>
             <Header />
             <div className='common-m-t'>
             </div>
             <section className='common-width'>
                <h1 className="title text-center"> Arush Foundation Gallery</h1>
                 
             </section>
             <section className='bgcolor-blue'>
             <p className='subtitle text-center color-white'> <strong>Welcome to ARUSH HEALTH FOUNDATION</strong></p>
             <p className='subtitle text-center color-white'>Arush Eduction and Helth foundtion is a Non-Government, Non-Political and Non-Profit voluntary organization registered under society act with registration number u88900UP2023NP L188522 and is approved by the Central and UP Government. It was established in 2023 with a commitment to change the social life style of the rural areas of Bhrat. We work for various different social cause including Water Sanitation, Woman Empowerment, Environment Protection, Edction,skill development,Helth ,AIDS Awareness etc.</p>
             </section>
             <section className=''>
             <section className='common-width'>
                  <GalleySection />
             </section>
             </section>
             
            <Footer />
        </div>
    )
}

export default Gallery