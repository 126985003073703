import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ImagesList from './ImagesList'


const Services = ()=>{
    return(
        <div>
             <Header />
             <div className='common-m-t'>
             </div>
             <section className='common-width'>
                <h1 className="title text-center"> Arush Foundation</h1>
                  <p className='subtitle text-center'> <strong>Welcome to ARUSH HEALTH FOUNDATION</strong></p>
                  <p>Arush Eduction and Helth foundtion is a Non-Government, Non-Political and Non-Profit voluntary organization registered under society act with registration number u88900UP2023NP L188522 and is approved by the Central and UP Government. It was established in 2023 with a commitment to change the social life style of the rural areas of Bhrat. We work for various different social cause including Water Sanitation, Woman Empowerment, Environment Protection, Edction,skill development,Helth ,AIDS Awareness etc.</p>
             </section>
             <section>
                <section className='bgcolor-blue'>
                    <section className='common-width'>
                        <h1 className='title text-center color-white common-m-tb'>
                            Our Services
                        </h1>
                        <p className='subtitle color-white text-center'> <strong>Process To Get The APAAR CARD</strong>
                            <br />

                        </p>
                        <p className='subtitle color-white text-center text-justify'>APAAR, which stands for Automated Permanent Academic Account Registry, is a specialized identification system designed for all students in India. This initiative is part of the 'One Nation, One Student ID' program launched by the government, aligning with the new National Education Policy of 2020.</p>
                        <p className='subtitle color-white text-center text-justify'>Your gateway to a seamless, digital academic journey. APAAR ID helps in a seamless integration of skills and experience into a credit-based system.</p>
                        <p className='subtitle color-white text-center text-center'> <strong>One Nation, One Student ID</strong></p>
                    </section>
                </section>
                <section className='common-width'>
                    <ImagesList />
                </section>
            </section>
            <Footer />
        </div>
    )
}

export default Services