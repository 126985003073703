import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './teams.css'

import abhishek from '../../assets/images/teams/abhishek.jpg'
import lalchand from '../../assets/images/teams/lalchand.jpg'
import shivaydubey from '../../assets/images/teams/shivaydubey.jpg'
import bal from '../../assets/images/teams/bal.png'
import pooja from '../../assets/images/teams/pooja.png'

import MutipleSlidesPerView from '../../CommonComponents/Sliders/MutipleSlidesPerView'
const teamList = [
    {
        icons: bal,
        name: 'Mr. BAL GOVIND RAJBHAR',
        designation: 'FOUNDER & CEO',
        email: 'arusheducation2418@gmail.com'
    },
    {
        icons: bal,
        name: ' Mr. RAM KEWAL RAJBHAR',
        designation: 'MANAGING DIRECTOR  ',
        email: 'arusheducation2418@gmail.com'
    },
    {
        icons: abhishek,
        name: 'Mr. ABHISHEK RAJBHAR ',
        designation: 'DIRECTOR MARKETING & C.F.O ',
        email: 'arusheducation2418@gmail.com'
    },
    {
        icons: lalchand,
        name: 'Mr. LAL CHAND YADAV ',
        designation: 'DIRECTOR HR & FINANCE',
        email: 'arusheducation2418@gmail.com'
    },
    {
        icons: bal,
        name: 'Mr. AMIT KUMAR DUBEY',
        designation: 'DIRECTOR BUSINESS DEVELOPMENT',
        email: 'arusheducation2418@gmail.com'
    },
    {
        icons: shivaydubey,
        name: ' Mr. SHIVAY DUBEY',
        designation: 'DIRECTOR MANAGEMENT & PUBLIC RELATION',
        email: 'arusheducation2418@gmail.com'
    },
    {
        icons: pooja,
        name: 'Mrs. POOJA RAJBHAR',
        designation: 'DIRECTOR    ADMINISTRATION',
        email: 'arusheducation2418@gmail.com'
    },
]


const Teams = () => {
    return (
        <div>
            <Header />
            <div className='common-m-t'>
            </div>
            <section className='common-width'>
                <h1 className="title text-center">Arush Foundation team member</h1>
            </section>
            <section className='team-section'>
                <section className='common-width'>
                    <div class="clearfixed">
                        {
                            teamList.map((list, index) => {
                                return (
                                    <div class="team-section-left">
                                        <div class="card">
                                            <div className='teams-img'><img src={list.icons} alt={list.name} /> </div>
                                            <div class="teams">
                                                <h1 className='title'>{list.name}</h1>
                                                <p class="team-subtitle"><strong>{list.designation}</strong></p>
                                                <p>{list.email}</p>
                                                <p><button class="button">Contact</button></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                </section>
                <section className='bgcolor-blue pb-20'>
                    <section className='common-width'>
                        <h1 className='title text-center color-white'>
                            Our Client
                        </h1>
                        <MutipleSlidesPerView />
                    </section>
                </section>
            </section>
            <Footer />
        </div>
    )
}

export default Teams