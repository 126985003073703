// src/components/TopBar.js
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './TopBar.css'; // Create separate CSS file for styles

const TopBar = ({ toggleSidebar, message }) => {
    const navigate = useNavigate();  // Hook to programmatically navigate
    useEffect(() => {
      // Check if token exists in localStorage
      const token = localStorage.getItem('authToken');
      if (!token) {
        // If no token, redirect to login page
        navigate('/admin-login');
      }
    }, [navigate]);
  return (
    <div className="topbar">
      <div className="topbar-left">
      <button className="menu-toggle" onClick={toggleSidebar}>☰</button>
      <h2>My Dashboard</h2>
      </div>
      <div className="topbar-right">
        <div className="user-info">
          <span>Welcome, Admin {message}</span>
          <button onClick={() => {
        // Logout: Remove the token and redirect to login page
        localStorage.removeItem('authToken');
        navigate('/admin-login');
      }} className="logout-btn">Logout</button>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
