import React from "react";
import './about.css'
const AboutTheApaar = ()=>{
    return(
        <div className="">
            <div className="common-width">
                  {/* <section className="bg-img-about">
                  </section> */}
                 <section className="bg-img-text color-white">
                    <h1 className="title text-center">About The APAAR Card</h1>
                    <p className="subtitle pd text-justify">
                    APAAR empowers students to accumulate and store their academic accomplishments, facilitating seamless transitions between institutions for the pursuit of further education.
                    This initiative is part of the 'One Nation, One Student ID' program launched by the government, aligning with the new National Education Policy of 2020.
                    </p>
                    <p className="subtitle pd text-justify">
                    APAAR ID - a unique 12-digit code will help students to digitally store, manage, and access all their academic credits, including Score card, marksheets, gradesheet, degrees, diplomas, certificates & co-curricular accomplishments. This ID functions as a permanent digital identity for the student in the education ecosystem
                    </p>
                    <p className="subtitle pd text-justify">
                    APAAR, which stands for Automated Permanent Academic Account Registry, is a specialized identification system designed for all students.
The APAAR ID simplifies the processes of credit recognition and transfer right from the school level, thereby streamlining academic progression and recognition of prior learning.

                    </p>
                    <p className="subtitle pd text-justify">
                    As soon as APAAR ID is generated, it is pushed into student's DigiLocker Account. Student can find virtual APAAR ID Card in Issued document section of DigiLocker. The status of APAAR ID generation can also be checked in the UDISE+ Portal under the APAAR Module, which displays a list of students along with their APAAR ID statuses. Students may request their school authority to check the status of their APAAR ID creation.
                    </p>
                 </section>
                

            </div>
        </div>
    )
}

export default AboutTheApaar;