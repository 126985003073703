// src/components/MainContent.js
import React from 'react';
import './MainContent.css'; // Create separate CSS file for styles

const MainContent = () => {
  return (
    <div className="main-content">
      <h2>Welcome to the Dashboard!</h2>
      <p>Here is some content to show on the dashboard.</p>
      <div className="card-container">
        <div className="card-admin">
          <h3>Total Users</h3>
          <p>150</p>
        </div>
        <div className="card-admin">
          <h3>Active Users</h3>
          <p>120</p>
        </div>
        <div className="card-admin">
          <h3>Revenue</h3>
          <p>$5000</p>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
