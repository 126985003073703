// src/components/Dashboard.js
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ClientSidebar from './ClientSidebar';
import ClientNavbar from './ClientNavbar';
import ClientDemoDashboard from './ClientDemoDashboard';
const ClientDashboard = () => {
  const navigate = useNavigate();  // Hook to programmatically navigate
  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      // If no token, redirect to login page
      navigate('/client-login');
    }
  }, [navigate]);

  return (
    <div>
      <div className="client-app-container">
      <ClientNavbar />
      <div className="client-main-content">
        <ClientSidebar />
        <ClientDemoDashboard />
      </div>
    </div>
      
    </div>
  );
};

export default ClientDashboard;
