// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

import './Sidebar.css'; // Create separate CSS file for styles

const Sidebar =  ({ isOpen }) => {

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <ul>
        <li>
          <Link to="/admin-dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/admin-dashboard">Profile</Link>
        </li>
        <li>
          <Link to="/admin-dashboard">Settings</Link>
        </li>
        <li>
          <Link to="/admin-dashboard">Reports</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
