import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.jpg'
import certificate from '../../assets/images/pdf/certificate.pdf'
import './header.css'
const Header = () => {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	return (
		<div>
			<AppBar position="fixed">
				<div className='display-none' >
					<section className='display-property'>
						<div>
							<Link to="/">
								<img src={logo} id='logo-size' />
							</Link>
						</div>
						<div onClick={handleDrawerToggle}>
							<MenuIcon className='menubar-color' />
						</div>
					</section>

				</div>
				<ul className={mobileOpen ? 'header-top display-none' : 'header-top display-block height-100'}>
					<li id='logo'>
						<div className='logo display-block'  >
							<Link to="/">
								<img src={logo} id='logo-size' />
								<span className='logo-title'>Arush Foundation</span>
							</Link>
						</div>

					</li>
					<li className='heght-fixed'>
						<Link to="/">Home</Link>
					</li>
					<li className='heght-fixed'>
						<Link to="/about">About</Link>
					</li>
					<li className='heght-fixed'>
						<Link to="/services">Services</Link>
					</li>
					<li className='heght-fixed'>
						<Link to="/gallery">Gallery</Link>
					</li>
					<li className='heght-fixed'>
						<a href={certificate} target="_blank"
							rel="noreferrer">
						Legal documents
						</a>
					</li>

					<li className='heght-fixed'>
						<Link to="/teams">Teams</Link>
					</li>
					<li className='heght-fixed'>
						<Link to="/contact">Contact</Link>
					</li>
					<li className='heght-fixed '>

						<div className="dropdown">
							<a> Login</a>
							<div className="dropdown-content">
								<Link to="/admin-login">Admin</Link>
								<Link to="/client-login">Client</Link>
							</div>
						</div>

						<div className='menu-hide-show'>
							<div>
								<Link to="/admin-login">Admin</Link>
							</div>
							<div>
								<Link to="/clinet-login">Client</Link>
							</div>
						</div>

					</li>
					<li className='heght-fixed'>
						<Link to="/register">Register</Link>
					</li>
				</ul>
			</AppBar>
		</div>
	);
}
export default Header;
