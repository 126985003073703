import React, { useState } from 'react';
import './imglist.css'
import Parents from '../../assets/images/gallery/apaar5.png'
import Nation from '../../assets/images/gallery/apaar6.png'
import Submitted from '../../assets/images/gallery/apaar3.png'
import DigiLocker from '../../assets/images/gallery/apaar2.jpg'
import Students from '../../assets/images/gallery/apaar1.jpg'
import Generated from '../../assets/images/gallery/apaar5.png'



const itemData = [
  {
    id: 1,
    img: Parents,
    title: 'Educating Parents',
  },
  {
    id: 2,
    img: Submitted,
    title: 'Consent Submitted',
  },
  {
    id: 3,
    img: Generated,
    title: 'APAAR ID Generated ',

  },

  {
    id: 4,
    img: DigiLocker,
    title: 'Uploaded to DigiLocker',
  },
  {
    id: 5,
    img: Nation,
    title: 'One Nation',
  },
  {
    id: 6,
    img: Students,
    title: 'One Student ID',
  },


];




const ImagesList = () => {

  return (
    <section>
      <div className='list-img-section clearfixed'>

        {
          itemData.map((list) => {
            return (
              <div key={list.id} className='float-left-img'>
                <div className='img-width'>
                  <div className='img-title' >
                    <img src={list.img} className='fade-img' />
                    <h2 className='img-subtitle'>{list.title}</h2>
                  </div>
                </div>

              </div>
            )
          })
        }
      </div>
    </section>
  );
}

export default ImagesList